<template>
<div class="about-page w-full h-screen md:pt-4 md:p-0 p-2 md:pb-0 pb-6">
  <div class="titre">Estimez votre prime et souscrivez à votre assurance en 4 étapes.</div>
  <div class="sous text-center md:mt-5 mt-4"> C’est simple et c’est sans engagement</div>

  <div class="flex justify-center mt-12 web">
    <div class="w-1/4 mr-10">
      <div class="num mb-6 text-center">1.</div>
      <steepBloc :icons="money" nom="Devis"/>
    </div>

    <div class="w-1/4 mr-10">
      <div class="num mb-6 text-center">2.</div>
      <steepBloc :icons="smilet" nom="Identification"/>
    </div>

    <div class="w-1/4 mr-10">
      <div class="num mb-6 text-center">3.</div>
      <steepBloc :icons="note" nom="Complèter vos infos"/>
    </div>

    <div class="w-1/4">
      <div class="num mb-6 text-center">4.</div>
      <steepBloc :icons="valid" nom="Paiement"/>
    </div>
  </div>

  <div class="mt-12 mobile pl-4 pr-4 pb-6">
    <div class="flex justify-center w-full">
      <div class="w-1/2 mr-5">
        <div class="num mb-6 text-center">1.</div>
        <steepBloc :icons="money" nom="Devis" class="w-full"/>
      </div>

      <div class="w-1/2">
        <div class="num mb-6 text-center">2.</div>
        <steepBloc :icons="smilet" nom="Identification" class="w-full"/>
      </div>
    </div>

    <div class="flex justify-center w-full mt-6 pb-6 mb-6">
      <div class="w-1/2 mr-5">
        <div class="num mb-6 text-center">3.</div>
        <steepBloc :icons="note" nom="Complèter vos infos" class="w-full"/>
      </div>

      <div class="w-1/2">
        <div class="num mb-6 text-center">4.</div>
        <steepBloc :icons="valid" nom="Paiement" class="w-full"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import steepBloc from '@/components/cedro/accueil/steepBloc'
import smilet from '@/assets/images/png/smilet.png'
import note from '@/assets/images/png/note.png'
import money from '@/assets/images/png/money.png'
import valid from '@/assets/images/png/valid.png'

export default {
  name: 'index',
  components: {
    steepBloc
  },
  data () {
    return {
      smilet,
      note,
      money,
      valid
    }
  },
  created () {
    this.$store.dispatch('position', 1)
    this.$store.dispatch('saveFirstDevis', null)
    // this.$store.dispatch('addDevis', [])
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}
.mobile{
  display: none;
}
  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }

@media only screen and (max-width: 700px) {
  .about-page{
    width: 100%;
    height: 100%;
  }
  .titre{
    font-size: 28px;
    line-height: 37px;
  }
  .sous{
    font-size: 18px;
    line-height: 24px;
  }
  .num{
    font-size: 48.4571px;
    line-height: 25px;
  }
  .web{
    display: none;
  }
  .mobile{
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .about-page{
    width: 90%;
  }
  .titre{
    font-size: 35px;
    line-height: 47px;
  }
  .sous{
    font-size: 22px;
  }
  .num{
    font-size: 50.4571px;
    line-height: 25px;
  }
  .mr-10{
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .about-page{
    width: 90%;
  }
}
</style>
